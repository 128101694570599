export const scrollToFirstDangerElement = (retryNr: number) => {
  var elementList = document.getElementsByClassName('help is-danger')
  if (elementList.length) elementList[0]?.parentElement?.scrollIntoView(true)
  else if (retryNr < 8) delayScrollToFirstDangerElement(retryNr + 1)
}

export const delayScrollToFirstDangerElement = (retryNr = 0) => {
  setTimeout(() => scrollToFirstDangerElement(retryNr), 250)
}

export const scrollToElement = (elementId: string) => {
  document.getElementById(elementId)?.scrollIntoView(true)
}
