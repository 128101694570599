export const FACILITY_TYPE_SURETY_BOND: string = 'surety-bond'
export const FACILITY_TYPE_PROPERTY_BACKED: string = 'property-backed'

export interface FacilityIntroPage {
  pageTitle: string
  type: string
  sections: {
    icon: string
    title: string
    description?: string
    list?: string[]
  }[]
  footerInfo: {
    description: string
  }
}

export const FACILITY_APPLICATION_INTRO: Record<string, FacilityIntroPage> = {
  [FACILITY_TYPE_SURETY_BOND]: {
    pageTitle: 'Apply for Surety Bond Facility',
    type: FACILITY_TYPE_SURETY_BOND,
    sections: [
      {
        icon: 'clipboard',
        title: 'What to expect',
        description: undefined,
        list: [
          'The first step of applying for a bond facility is a standard basic assessment.',
          'Once submitted - we will review the information and pass on to our bond facility provider.',
          'If successful we will provide you with an <b>offer and non-binding terms</b>. You can choose to accept or decline the offer.'
        ]
      },
      {
        icon: 'info',
        title: 'Who can apply?',
        description:
          'We follow a methodical approach to providing bond facilities. Key factors in this process include:',
        list: [
          'A well-developed business',
          'A solid track record',
          'Evidence of professional financial and operational management',
          'Demonstrated capital retention within business',
          'Revenue of at least $20m'
        ]
      },
      {
        icon: 'clock',
        title: 'What we need from you',
        description: 'We will be asking for the following information:',
        list: [
          'Consolidated financial statements',
          'Most recent management accounts',
          'Group Structure',
          'A summary of the groups banking facilities',
          'Budgeted cashflow forecasts',
          'Detailed work in progress / projects'
        ]
      }
    ],
    footerInfo: {
      description: `Our surety bonds use AA- rated paper from Swiss Re International SE, one of the world's largest reinsurers.`
    }
  },
  [FACILITY_TYPE_PROPERTY_BACKED]: {
    pageTitle: 'Apply for Property Backed Facility',
    type: FACILITY_TYPE_PROPERTY_BACKED,
    sections: [
      {
        icon: 'clipboard',
        title: 'How does it work?',
        description: undefined,
        list: [
          'A bank guarantee facility secured by property allows your business to issue bank guarantees using equity within your property rather than cash.',
          'To set up a bank guarantee facility, the bank will need to value the property that is proposed to be used to secure the bank guarantees.',
          'The bank will register security over the property equal to the value of the facility.',
          'If a bank guarantee that has been issued using the facility is claimed by a beneficiary, the bank will pay the beneficiary in accordance with the bank guarantee.',
          'The bank will then require your business to pay the bank an amount equal to what was claimed by the beneficiary.',
          'If your business cannot repay the bank, the bank may take measures against the property used to secure the facility in order to obtain payment of the amount owing up to and including selling the property.'
        ]
      },
      {
        icon: 'info',
        title: 'Who can apply and how much does it cost?',
        description: '',
        list: [
          'A business that has property with an appropriate amount of equity within it may apply.',
          'The property does not have to be in the name of the business but the registered owners of the property will need to consent to the property being used as security for the bank guarantee facility.',
          'The bank will make an initial assessment of the application for a bank guarantee facility without charge.',
          'If the application is accepted by the bank and your business wishes to proceed, a $850 application fee is payable to establish the facility.',
          'Once established, guarantees issued using the facility will incur a $650 establishment fee and a 2% p.a. ongoing fee if residential property is used to secure the facility and a 3% p.a. ongoing fee if commercial premises are used to secure the facility.'
        ]
      },
      {
        icon: 'clock',
        title: 'What will we need from you?',
        description: undefined,
        list: [
          'To get your application started, we will need some basic information about your business and the property that is proposed to be used to secure the bank guarantee facility.',
          `We will need to know: <ul>
          <li>Business Name</li>
          <li>Australian Business Number</li>
          <li>Principal Place of Business</li>
          <li>Industry</li>
          <li>Address of the Property</li>
          <li>Value of the Property</li>
          <li>Estimated Equity within the Property</li>
          </ul>`,
          'We will also need to know if the property that is proposed to be sued as a security for the bank guarantee has a mortgage.',
          'If the property is mortgaged, in order to secure a bank guarantee facility the mortgage would need to be switched to the bank providing the bank guarantee facility.',
          'Ready to get started?'
        ]
      }
    ],
    footerInfo: {
      description:
        'Our Property Backed Facilities are secured by Beyond Bank Australia. This partnership ensures robust financial backing for your guarantees, providing you and your beneficiaries with peace of mind. Learn more about our security and processes. '
    }
  }
}
