import { saveAs } from 'file-saver'
import { unparse } from 'papaparse'
import { v4 as uuid } from 'uuid'
import { BankName } from '~/constants'
import { toLongDate } from '~/utils'

export class handleExportCSV {
  historyGroup: any
  type: string

  constructor(historyGroup: any, type: string) {
    this.historyGroup = historyGroup
    this.type = type
  }
  get triggeredBy() {
    return this.historyGroup.triggeredBy || null
  }

  get isCreatedChange() {
    return (
      !this.historyGroup.changes?.length && this.historyGroup.changeType === 'INSERT' && !this.historyGroup.recordName
    )
  }

  get hasChanges() {
    return this.historyGroup.changes?.length
  }

  getAuthorName(triggeredBy: any) {
    if (!triggeredBy) return ''
    if (triggeredBy?.isBankAuthor) return BankName
    else if (triggeredBy?.givenName && triggeredBy?.familyName)
      return [triggeredBy.givenName, triggeredBy.familyName].join(' ')
    else return triggeredBy
  }

  getChangeLabel(event: any) {
    let label = ''
    if (event.changeType === 'UPDATE') label = event.recordName ? `Changed ${event.recordName}'s details` : 'Changed'
    if (event.changeType === 'INSERT') label = event.recordName ? `Created the ${event.recordName}` : 'Created'
    if (event.changeType === 'DELETE') label = event.recordName ? `Deleted the ${event.recordName}` : 'Deleted'
    return label + (this.hasChanges ? ':' : '')
  }

  explodeChanges(history: any[]): any[] {
    let explodedHistory: any[] = []
    for (const event of history) {
      const { changes, changeType, objectType, triggeredBy, triggeredAt, recordName } = event
      if (changes.length < 1) {
        explodedHistory.push({
          key: uuid(),
          ...event
        })
      } else {
        for (const change of changes) {
          const { field, newValue, oldValue, label, oldDisplayValue, newDisplayValue } = change
          if (field !== 'DocumentId' && field !== 'TriggeredBy')
            explodedHistory.push({
              key: uuid(),
              field,
              recordName,
              newValue,
              oldValue,
              label,
              oldDisplayValue,
              newDisplayValue,
              changeType,
              objectType,
              triggeredBy,
              triggeredAt
            })
        }
      }
    }
    return explodedHistory
  }

  getDocumentChangeDescription(event: any): string {
    const eventOwner =
      this.getAuthorName(event.triggeredBy) +
      (this.isCreatedChange ? ' created the ' + this.type + ':' : ' amended the ' + this.type + ':')
    const eventChangeType = this.getChangeLabel(event)
    const eventFieldName = event.label ? event.label : event.field
    var eventChangeDetail = ''
    if (event.changeType === 'UPDATE')
      eventChangeDetail =
        ' from "' +
        (event.oldDisplayValue ? event.oldDisplayValue : event.oldValue) +
        '" to "' +
        (event.newDisplayValue ? event.newDisplayValue : event.newValue) +
        '"'
    if (event.changeType === 'INSERT')
      eventChangeDetail = ' "' + (event.newDisplayValue ? event.newDisplayValue : event.newValue) + '"'
    if (event.changeType === 'DELETE')
      eventChangeDetail = ' "' + (event.oldDisplayValue ? event.oldDisplayValue : event.oldValue) + '"'
    return `${eventOwner} ${eventChangeType} ${eventFieldName}${eventChangeDetail}`.trim()
  }

  public async handleExportCSV() {
    let allActivity = []
    allActivity = this.historyGroup
    const parsedActivity = this.explodeChanges(allActivity)
    const exportData = parsedActivity.map(change => {
      const {
        field = '',
        newValue = '',
        oldValue = '',
        label = '',
        oldDisplayValue = '',
        newDisplayValue = '',
        changeType = '',
        objectType = '',
        triggeredAt = '',
        triggeredBy = null
      } = change
      const eventOwner = this.getAuthorName(triggeredBy)
      return {
        Message: this.getDocumentChangeDescription(change),
        'Event Owner': eventOwner,
        Timestamp: triggeredAt,
        'Change Type': changeType,
        'Object Type': objectType,
        Field: label ? label : field,
        'Old Value': oldDisplayValue ? oldDisplayValue : oldValue,
        'New Value': newDisplayValue ? newDisplayValue : newValue
      }
    })
    const data = unparse(exportData, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false,
      escapeFormulae: true
    })
    const blob = new Blob([data], {
      type: 'text/plain;charset=utf-8'
    })
    saveAs(blob, this.type + ` Assuro Activity - ${toLongDate(new Date())}.csv`)
  }
}
